<script setup lang="ts">
import { z } from "zod";
import type { FormSubmitEvent } from "#ui/types";

const { t } = useI18n();

const closeForm = inject<() => void>("close-form");

const { exchanges } = useExchanges();

interface Trade {
  id: number;
  symbol: string;
}

const coinStore = useCoinStore();

const schema = z.object({
  fromDate: z.coerce.date(),
});

type Schema = z.output<typeof schema>;

const trade = ref({
  exchange: {
    id: exchanges[0].key,
    label: exchanges[0].name,
    avatar: exchanges[0].avatar,
    localLogo: exchanges[0].localLogo,
  },
  fromDate: new Date().toISOString().split("T")[0],
  majorCoin: coinStore.coins.find((c) => c.symbol === "BTC"),
  minorCoin: coinStore.coins.find((c) => c.symbol === "USDT"),
});

const selectedExchange = computed(() => {
  return exchanges.find((ex) => ex.key === trade.value.exchange.id);
});

const userStore = useUserStore();
const isIntegrated = computed(() => {
  const userExchange = userStore.user[trade.value.exchange.id];
  return userExchange !== null && userExchange !== undefined;
});

const router = useRouter();
const goToIntegrations = () => {
  //call the injected closeForm function
  closeForm();
  router.push("/system/integration");
};

const searching = ref(false);
const newTrades = ref([]);
const toast = useToast();

const step = ref(2);
const stepTwo = computed(() => {
  return newTrades.value.length > 0 && step.value === 2;
});

const externalApiHeaders = await useApiHeaders();

const search = async (event: FormSubmitEvent<Schema>) => {
  searching.value = true;
  step.value = 2;
  const obj = {
    coinPair: trade.value.majorCoin.symbol + trade.value.minorCoin.symbol,
    fromDate: trade.value.fromDate,
    exchange: trade.value.exchange.id,
    symbol: trade.value.majorCoin.symbol,
  };

  try {
    const { data: trades } = await $fetch("/api/trade/exchange", {
      ...externalApiHeaders,
      method: "POST",
      body: JSON.stringify(obj),
    });
    newTrades.value = trades;
    if (!trades || trades.length == 0) {
      toast.add({
        title: t("messages.no-trades"),
        color: "amethyst",
        icon: "i-material-symbols-info-outline",
      });
    }
    searching.value = false;
  } catch (error) {
    toast.add({
      title: t("messages.saving-error"),
      color: "red",
      icon: "i-heroicons-exclamation-circle-solid",
    });
    searching.value = false;
  }
};

//step 2

const { formatDateAndTime } = useFilters();

const columns = computed(() => {
  return [
    {
      key: "registeredOn",
      label: "Date",
    },
    {
      key: "coin",
      label: "Coin",
    },
    {
      key: "price",
      label: "Price",
    },
    {
      key: "amount",
      label: "Amount",
    },
    {
      key: "total",
      label: "Total",
    },
    {
      key: "fee",
      label: "Fee",
    },
    {
      key: "hodl",
      label: "HODL",
    },
  ];
});

const selected = ref([]);

const tradeStore = useTradeStore();

const adding = ref(false);
const reloadApp = useState("reloadApp");
async function add() {
  adding.value = true;
  const addedTrades = [];
  for (const item of selected.value) {
    const newTrade = {
      registeredOn: item.registeredOn,
      coinId:
        trade.value.exchange.id === "firi"
          ? item.coinId
          : trade.value.majorCoin.coinId,
      coinName:
        trade.value.exchange.id === "firi"
          ? item.coinName
          : trade.value.majorCoin.symbol,
      price: item.price,
      total: item.total,
      amount: item.amount,
      tradeType: "spot",
      leverage: 1,
      side: "buy",
      exchange: trade.value.exchange.id,
      longterm: item.longterm,
      quote:
        trade.value.exchange.id === "binance"
          ? trade.value.minorCoin.symbol
          : item.quote,
      reference: item.reference,
      fee: item.fee,
    };

    try {
      const userStore = useUserStore();
      const { data } = await $fetch("/api/trade", {
        ...externalApiHeaders,
        method: "POST",
        body: JSON.stringify(newTrade),
      });
      tradeStore.activeTrades.push(data);
      userStore.setTradePosition();

      addedTrades.push(item);
      newTrades.value = newTrades.value.filter(
        (t) => t.reference !== item.reference,
      );
      toast.add({
        title: t("messages.new-trade-added"),
        color: "green",
        icon: "i-heroicons-check-circle-solid",
      });
      adding.value = false;
    } catch (error) {
      toast.add({
        title: t("messages.saving-error"),
        color: "red",
        icon: "i-heroicons-exclamation-circle-solid",
      });
      adding.value = false;
    }
  }

  // clear the list
  for (const t of addedTrades) {
    //remove all the items in the newTrades array matching the t.reference. There can be more than one item with the same reference
    newTrades.value = newTrades.value.filter(
      (item) => item.reference !== t.reference,
    );

    //remove the item from the selected array
    const index = selected.value.indexOf(t);
    selected.value.splice(index, 1);
  }

  //reload active trades
  reloadApp.value = true;
}
</script>

<template>
  <UForm
    :state="trade"
    :schema="schema"
    class="mt-6 space-y-4"
    @submit="search"
    v-if="!stepTwo">
    <UFormGroup label="Select exchange" name="exchange">
      <ExchangePicker v-model="trade.exchange.id" :only-integrated="true" />
    </UFormGroup>

    <div class="text-center" v-if="!isIntegrated">
      <UIcon name="carbon:integration" class="w-8 h-8" />
      <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-gray-400">
        Integration not added
      </h3>
      <p class="mt-1 text-sm text-gray-500">
        Get started by setting up the integration.
      </p>
      <div class="mt-6">
        <UButton
          icon="i-heroicons-plus"
          color="primary"
          variant="soft"
          label="Add Integration"
          @click="goToIntegrations">
        </UButton>
      </div>
    </div>

    <div v-if="isIntegrated" class="flex flex-col space-y-4">
      <UFormGroup
        :label="t('coin-pair')"
        name="coinPair"
        v-if="trade.exchange.id != 'firi'">
        <CoinPair
          v-model:major-coin="trade.majorCoin"
          v-model:minor-coin="trade.minorCoin" />
      </UFormGroup>

      <UFormGroup
        :label="t('from') + ' ' + t('date').toLowerCase()"
        name="fromDate">
        <UInput type="date" v-model="trade.fromDate" />
      </UFormGroup>
    </div>

    <div class="right-0 w-full pt-10 lg:fixed lg:bottom-0">
      <UDivider color="gray" />
      <div class="flex justify-end w-full gap-3 p-4">
        <UButton
          color="gray"
          variant="ghost"
          label="Cancel"
          :trailing="false"
          type="button"
          @click="closeForm" />

        <UButton
          v-if="isIntegrated"
          color="primary"
          icon="i-material-symbols-light-search-rounded"
          variant="solid"
          :label="searching ? 'Searching...' : 'Search'"
          class="mr-2"
          type="submit"
          :loading="searching" />
      </div>
    </div>
  </UForm>

  <div v-else>
    <div class="h-[72vh] overflow-auto">
      <span class="flex items-center space-x-3">
        <div>
          <UAvatar
            :src="`/images/${selectedExchange.localLogo}`"
            size="2xl"></UAvatar>
        </div>
        <UBadge variant="subtle" v-if="trade.exchange.id === 'binance'"
          >{{ trade.majorCoin.symbol }}/{{ trade.minorCoin.symbol }}</UBadge
        >
      </span>

      <!-- Table -->
      <UTable v-model="selected" :rows="newTrades" :columns="columns">
        <template #registeredOn-data="{ row }">
          {{ formatDateAndTime(row.registeredOn) }}
        </template>

        <template #coin-data="{ row }">
          {{ row.coinName }}/ {{ row.quote }}
        </template>

        <template #fee-data="{ row }">
          {{ row.fee.commission }} {{ row.fee.asset }}
        </template>

        <template #hodl-data="{ row }">
          <UToggle color="primary" v-model="row.longterm" />
        </template>
      </UTable>
    </div>

    <div class="right-0 w-full pt-10 sm:fixed sm:bottom-0">
      <UDivider color="gray" />
      <div class="flex justify-end w-full gap-3 p-4">
        <UButton
          color="gray"
          variant="ghost"
          label="Cancel"
          :trailing="false"
          type="button"
          @click="closeForm" />

        <UButton
          color="gray"
          variant="outline"
          label="Back"
          :trailing="false"
          icon="i-heroicons-arrow-left"
          type="button"
          @click="step = 1" />

        <UButton
          v-if="isIntegrated"
          :disabled="selected.length === 0"
          color="primary"
          icon="i-heroicons-plus"
          variant="solid"
          :label="adding ? 'Adding...' : 'Add Trades'"
          class="mr-2"
          type="button"
          :loading="adding"
          @click="add" />
      </div>
    </div>
  </div>
</template>
