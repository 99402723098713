<script lang="ts" setup>
const items = [
  {
    key: "exchange",
    label: "Via Integration",
  },
  {
    key: "manual",
    label: "Manually",
  },
];

const userStore = useUserStore();
const isPremium = await userStore.isPremiumUser();
</script>

<template>
  <UTabs :items="items">
    <template #item="{ item }">
      <NewTradeViaIntegration v-if="item.key === 'exchange' && isPremium" />
      <UpgradeToPremium
        image="newtrade"
        v-else-if="item.key === 'exchange' && !isPremium" />
      <NewTradeManual v-if="item.key === 'manual'" />
    </template>
  </UTabs>
</template>
