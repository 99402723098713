<script setup>
const links = [
  {
    label: "Documentation",
    to: "https://docs.vimicrypto.com/help/create-account.html",
  },
  {
    label: "Terms of services",
    to: "/terms",
  },
  {
    label: "Privacy Policy",
    to: "/privacy",
  },
];
</script>

<template>
  <UFooter :links="links">
    <template #left> Copyright © {{ new Date().getFullYear() }} </template>

    <template #right>
      <UButton
        icon="i-simple-icons-x"
        color="gray"
        variant="ghost"
        to="https://twitter.com/viminorge"
        target="_blank" />
    </template>
  </UFooter>
</template>
