<script setup lang="ts">
import { z } from "zod";
import type { FormSubmitEvent } from "#ui/types";

const closeForm = inject<() => void>("close-form");
const { t } = useI18n();

const schema = z.object({
  registeredOn: z.coerce.date(),
  price: z.number().min(0.00000001),
  total: z.number().min(0.00000001),
});

type Schema = z.output<typeof schema>;

const coinStore = useCoinStore();

const trade = ref({
  exchange: "binance",
  registeredOn: new Date().toISOString().split("T")[0],
  price: 0,
  leverage: 1,
  total: 0,
  longterm: false,
  fee: {
    commission: 0,
    asset: coinStore.coins.find((c) => c.symbol === "USDT"),
  },
});

const majorCoin = ref(coinStore.coins.find((c) => c.symbol === "BTC"));
const minorCoin = ref(coinStore.coins.find((c) => c.symbol === "USDT"));

const isFuture = ref(false);

const amount = computed(() => {
  if (trade.value.price === 0) {
    return 0;
  }

  const totalSize = !isFuture.value
    ? trade.value.total
    : trade.value.total * trade.value.leverage;
  return (totalSize / trade.value.price).toFixed(4);
});

const tradeStore = useTradeStore();
const toast = useToast();
const router = useRouter();
const reloadApp = useState("reloadApp");

const userSettings = useSettings();

const saving = ref(false);
const save = async (event: FormSubmitEvent<Schema>) => {
  saving.value = true;
  const newTrade = {
    exchange: trade.value.exchange,
    tradeType: isFuture.value ? "future" : "spot",
    coinId: majorCoin.value.coinId,
    coinName: majorCoin.value.symbol,
    quote: minorCoin.value?.symbol,
    quoteCoinId: minorCoin.value?._id,
    registeredOn: trade.value.registeredOn,
    price: trade.value.price,
    leverage: trade.value.leverage,
    total: trade.value.total,
    longterm: trade.value.longterm,
    fee: {
      commission: trade.value.fee.commission,
      asset: trade.value.fee.asset?.symbol,
    },
  };

  if (
    userSettings &&
    userSettings.value &&
    userSettings.value.trade &&
    userSettings.value.trade.autoPosition == false
  ) {
    newTrade.position = trade.value.position;
  }

  try {
    await tradeStore.addNewTrade(newTrade);
    toast.add({
      title: t("messages.new-trade-added"),
    });
    saving.value = false;
    reloadApp.value = true;
    closeForm();
  } catch (error) {
    toast.add({
      title: t("messages.saving-error"),
    });
    saving.value = false;
  }
};
</script>

<template>
  <UForm :state="trade" :schema="schema" @submit="save">
    <div class="my-6 px-1 space-y-4 h-[72vh] overflow-auto">
      <UFormGroup
        label="Position"
        name="position"
        v-if="
          userSettings &&
          userSettings.trade &&
          userSettings.trade.autoPosition == false
        ">
        <UInput
          type="number"
          v-model.number="trade.position"
          min="1"
          trailing
          icon="i-clarity-times-line" />
      </UFormGroup>

      <UFormGroup label="Exchange" name="exchange">
        <ExchangePicker v-model="trade.exchange" />
      </UFormGroup>

      <UFormGroup label="Future trade" name="isFuture">
        <UToggle color="primary" v-model="isFuture" />
      </UFormGroup>

      <UFormGroup label="Leverage" name="leverage" v-if="isFuture">
        <UInput
          type="number"
          v-model.number="trade.leverage"
          trailing
          icon="i-clarity-times-line" />
      </UFormGroup>

      <UFormGroup label="Coin pair" name="coinPair">
        <CoinPair
          v-model:major-coin="majorCoin"
          v-model:minor-coin="minorCoin" />
      </UFormGroup>

      <UFormGroup :label="t('date')" name="registeredOn">
        <UInput type="date" v-model="trade.registeredOn" />
      </UFormGroup>

      <UFormGroup :label="t('price')" name="price">
        <NumberInput v-model="trade.price" />
      </UFormGroup>

      <UFormGroup label="Total" name="total">
        <div class="flex items-center justify-between">
          <NumberInput v-model="trade.total">
            <template #trailing>
              <div class="flex items-center ml-1 space-x-1">
                <UAvatar
                  :src="`https://s2.coinmarketcap.com/static/img/coins/64x64/${minorCoin?.coinId}.png`"
                  size="3xs" />
                <span class="-ml-1 text-xs text-gray-500 dark:text-gray-400">
                  {{ minorCoin?.symbol }}
                </span>
              </div>
            </template>
          </NumberInput>
          <UBadge color="primary" variant="subtle" size="md" v-if="amount > 0">
            <div class="flex items-center space-x-2">
              <UIcon class="text-sm text-yellow-300" name="ph:coins-thin" />
              <span>{{ amount }}</span>
            </div>
          </UBadge>
        </div>
      </UFormGroup>

      <UFormGroup label="Fee" name="fee">
        <NumberInput
          v-model="trade.fee.commission"
          :ui="{
            base: 'text-right',
            rounded: 'rounded-r-none',
          }"
          size="md">
          <template #trailing>
            <CoinDropdown
              v-model="trade.fee.asset"
              :ui="{ rounded: 'rounded-l-none' }" />
          </template>
        </NumberInput>
      </UFormGroup>

      <UFormGroup
        label="HODL"
        name="longterm"
        description="Is this trade going to be held as a longterm investment?">
        <UToggle color="primary" v-model="trade.longterm" />
      </UFormGroup>
    </div>

    <div class="fixed bottom-0 right-0 w-full pt-10">
      <UDivider color="gray" />
      <div class="flex justify-end w-full gap-3 p-4">
        <UButton
          color="gray"
          variant="ghost"
          label="Cancel"
          :trailing="false"
          type="button"
          @click="closeForm" />

        <UButton
          color="primary"
          variant="solid"
          label="Add Trade"
          :trailing="false"
          class="mr-2"
          type="submit"
          :loading="saving" />
      </div>
    </div>
  </UForm>
</template>

<style lang="scss" scoped></style>
