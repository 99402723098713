<script lang="ts" setup>
const isOpen = ref(false);

const close = () => {
  isOpen.value = false;
};
provide("close-form", close);
</script>

<template>
  <div>
    <UButton
      icon="i-heroicons-plus"
      size="sm"
      color="primary"
      label="New Trade"
      :trailing="false"
      class="hidden sm:flex"
      @click="isOpen = true" />
    <UButton
      icon="i-heroicons-plus"
      size="sm"
      color="primary"
      variant="soft"
      :trailing="false"
      class="flex sm:hidden"
      @click="isOpen = true" />

    <USlideover v-model="isOpen" :ui="{ width: 'max-w-4xl' }">
      <UCard
        class="flex flex-col flex-1 h-full"
        :ui="{
          body: { base: 'flex-1' },
          ring: '',
          divide: 'divide-y divide-gray-100 dark:divide-gray-800',
        }">
        <template #header>
          <div class="flex items-center justify-between">
            <h3
              class="text-base font-semibold leading-6 text-gray-900 dark:text-mercury-500">
              Add new trade
            </h3>
            <UButton
              color="gray"
              variant="ghost"
              icon="i-heroicons-x-mark-20-solid"
              class="-my-1"
              @click="isOpen = false" />
          </div>
        </template>

        <div>
          <NewForm />
        </div>
      </UCard>
    </USlideover>
  </div>
</template>

<style lang="scss" scoped></style>
