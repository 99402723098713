<script setup>
import { Bars3Icon } from "@heroicons/vue/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";

defineProps({
  sidebarOpen: Boolean,
});

defineEmits(["sidebarOpen"]);

const userStore = useUserStore();
await userStore.getUserNotifications();

const { signOutVimiUser } = useVimiAuth();
const firebaseUser = useCurrentUser();

const sorted = computed(() => {
  const sortedArray = userStore.notifications.sort((first, second) => {
    return first.createdAt > second.createdAt ? -1 : 1;
  });

  const lastTen = sortedArray.slice(0, 9);
  const final = lastTen.map((notification) => {
    return {
      label: notification.title,
      to: notification.routeName,
      exactHash: true,
      icon: notification.icon,
      description: notification.content,
      active: !notification.read,
    };
  });
  return final;
});

const count = computed(
  () => userStore.notifications.filter((s) => !s.read).length,
);

const markAsRead = async () => {
  userStore.markNotificationAsRead();
};

const openProfile = ref(false);

const firstLinks = [
  {
    label: "Profile",
    to: "/system/user/account/general",
    icon: "i-carbon-user",
    click: () => (openProfile.value = false),
  },
  {
    label: "Security",
    to: "/system/user/account/security",
    icon: "i-heroicons-magnifying-glass",
    click: () => (openProfile.value = false),
  },
  {
    label: "Billing",
    to: "/system/user/account/billing",
    icon: "i-heroicons-arrows-right-left",
    click: () => (openProfile.value = false),
  },
];
</script>

<template>
  <div
    class="sticky top-0 z-40 flex items-center h-16 px-4 bg-white border-b border-gray-200 shadow-sm shrink-0 gap-x-6 dark:border-white/5 dark:bg-gray-900 sm:gap-x-6 sm:px-6 lg:px-8">
    <button
      type="button"
      class="-m-2.5 p-2.5 text-gray-700 dark:text-mercury-500 xl:hidden"
      @click="$emit('sidebarOpen')">
      <span class="sr-only">Open sidebar</span>
      <Bars3Icon class="w-5 h-5" aria-hidden="true" />
    </button>

    <!-- Separator -->
    <div class="w-px h-6 bg-gray-200 lg:hidden" aria-hidden="true" />

    <div class="flex self-stretch flex-1 gap-x-4 lg:gap-x-6">
      <form class="relative flex flex-1" action="#" method="GET">
        <label for="search-field" class="sr-only">Search</label>
        <MagnifyingGlassIcon
          class="absolute inset-y-0 left-0 w-5 h-full text-gray-400 pointer-events-none dark:text-gray-200"
          aria-hidden="true" />
        <input
          id="search-field"
          class="block w-full h-full py-0 pl-8 pr-0 text-gray-900 border-0 dark:text-mercury-500 dark:bg-transparent placeholder:text-gray-400 focus:ring-0 sm:text-sm"
          placeholder="Search..."
          type="search"
          name="search" />
      </form>

      <div class="flex items-center gap-x-4 lg:gap-x-6">
        <NewTrade />

        <UPopover>
          <UChip :text="count" size="2xl" :show="count > 0">
            <UButton
              icon="i-heroicons-bell"
              color="white"
              @click="markAsRead" />
          </UChip>

          <template #panel>
            <LazyUHeaderPopoverLinks :links="sorted" class="w-full" />
          </template>
        </UPopover>

        <UColorModeButton />

        <UPopover v-model:open="openProfile">
          <UButton variant="ghost">
            <template #leading>
              <UAvatar
                v-if="userStore.user && userStore.user.photoURL"
                :src="userStore.user?.photoURL" />
              <UAvatar v-else icon="i-carbon-user" />
            </template>
          </UButton>

          <template #panel>
            <div class="flex flex-col gap-2 px-4">
              <div class="flex flex-col gap-2 pt-2">
                <div class="text-xs">Signed in as</div>
                {{ firebaseUser?.displayName ?? firebaseUser?.email }}
              </div>

              <UDivider />

              <LazyUHeaderPopoverLinks
                :links="firstLinks"
                :ui="{ wrapper: 'p-0' }" />

              <UDivider />

              <UButton
                variant="link"
                class="mb-2"
                icon="i-carbon-logout"
                @click="signOutVimiUser"
                >Logout</UButton
              >
            </div>
          </template>
        </UPopover>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
