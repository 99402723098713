<script setup>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
// import { SpeedInsights } from "@vercel/speed-insights/vue";

//get the rout path
const route = useRoute();

const navigation = computed(() => [
  {
    name: "Home",
    href: "/system",
    icon: "i-carbon-home",
    current: route.path === "/system",
  },
  {
    name: "Trade",
    href: "/system/trade",
    icon: "i-uil-transaction",
    current: route.path === "/system/trade",
    submenu: [
      {
        name: "Closed trades",
        href: "/system/trade/closed",
        icon: "i-heroicons-clipboard-document-check-solid",
        current: route.path === "/system/trade/closed",
      },
    ],
  },
  {
    name: "Integrations",
    href: "/system/integration",
    icon: "i-carbon-integration",
    current: route.path === "/system/integration",
  },
  {
    name: "Reports",
    href: "/system/reports",
    icon: "i-carbon-chart-column",
    current: route.path.includes("/system/reports"),
  },
]);

const setCurrent = (item) => {
  sidebarOpen.value = false;
};

const shortcuts = computed(() => [
  {
    id: 1,
    name: "Settings",
    href: "/system/settings",
    icon: "i-carbon-settings",
    current: route.path === "/system/settings",
  },
  {
    id: 2,
    name: "Documentation",
    href: "https://docs.vimicrypto.com/help/create-account.html",
    icon: "i-heroicons-book-open",
    current: false,
  },
]);

const sidebarOpen = ref(false);
const sidebarCollapsed = ref(false);
</script>

<template>
  <div>
    <!-- <SpeedInsights /> -->

    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="relative z-50 xl:hidden"
        @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full">
            <DialogPanel class="relative flex flex-1 w-full max-w-xs mr-16">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0">
                <div
                  class="absolute top-0 flex justify-center w-16 pt-5 left-full">
                  <button
                    type="button"
                    class="-m-2.5 p-2.5"
                    @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="w-6 h-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div
                class="flex flex-col px-6 overflow-y-auto bg-white grow gap-y-5 dark:bg-gray-900 ring-1 ring-white/10">
                <div class="flex items-center h-16 shrink-0">
                  <VimiLogo class="w-48 h-16" alt="VimiCrypto Logo" />
                </div>
                <nav class="flex flex-col flex-1">
                  <ul role="list" class="flex flex-col flex-1 gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in navigation" :key="item.name">
                          <NuxtLink
                            :to="item.href"
                            @click="setCurrent(item)"
                            :class="[
                              item.current
                                ? 'bg-gray-50 dark:bg-gray-800 text-indigo-600 dark:text-mercury-500'
                                : 'text-gray-700 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-white  hover:bg-gray-50 dark:hover:bg-gray-800',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                            ]">
                            <UIcon
                              :name="item.icon"
                              class="w-6 h-6 shrink-0"
                              aria-hidden="true" />
                            {{ item.name }}
                          </NuxtLink>

                          <!-- Submenu -->
                          <ul v-if="item.submenu" class="pl-8">
                            <li
                              v-for="subItem in item.submenu"
                              :key="subItem.name">
                              <NuxtLink
                                :to="subItem.href"
                                @click="setCurrent(subItem)"
                                :class="[
                                  subItem.current
                                    ? 'bg-gray-50 dark:bg-gray-800 text-indigo-600 dark:text-mercury-500'
                                    : 'text-gray-700 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                ]">
                                <UIcon
                                  :name="subItem.icon"
                                  class="w-6 h-6 shrink-0"
                                  aria-hidden="true" />
                                {{ subItem.name }}
                              </NuxtLink>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div
                        class="text-xs font-semibold leading-6 text-gray-400">
                        Shortcuts
                      </div>
                      <ul role="list" class="mt-2 -mx-2 space-y-1">
                        <li v-for="shortcut in shortcuts" :key="shortcut.name">
                          <NuxtLink
                            :to="shortcut.href"
                            @click="setCurrent(shortcut)"
                            :class="[
                              shortcut.current
                                ? 'bg-gray-50 dark:bg-gray-800 text-indigo-600 dark:text-mercury-500'
                                : 'text-gray-700 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                            ]">
                            <UIcon :name="shortcut.icon" class="w-6 h-6" />

                            <span class="truncate">{{ shortcut.name }}</span>
                          </NuxtLink>
                        </li>
                      </ul>
                    </li>
                    <!-- <li class="mt-auto -mx-6">
                      <Menu />
                    </li> -->
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div
      class="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:flex-col"
      :class="[sidebarCollapsed ? 'xl:w-[80px]' : 'xl:w-[240px]']">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="flex flex-col items-center px-6 overflow-y-auto bg-white grow gap-y-5 dark:bg-black/10 ring-1 ring-black/5 dark:ring-white/5">
        <NuxtLink to="/">
          <NuxtImg
            v-if="sidebarCollapsed"
            src="/images/logo.svg"
            class="h-24 w-36"
            alt="VimiCrypto Logo" />
          <VimiLogo v-else class="w-48 h-16" alt="VimiCrypto Logo" />
        </NuxtLink>
        <nav class="flex flex-col items-center flex-1 w-full">
          <ul
            role="list"
            class="flex flex-col items-center flex-1 w-full gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in navigation" :key="item.name">
                  <NuxtLink
                    :to="item.href"
                    @click="setCurrent(item)"
                    :class="[
                      item.current
                        ? 'bg-gray-50 dark:bg-gray-800 text-indigo-600 dark:text-mercury-500'
                        : 'text-gray-700 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800',
                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                    ]">
                    <UIcon
                      :name="item.icon"
                      class="w-6 h-6 shrink-0"
                      aria-hidden="true" />
                    <span v-if="!sidebarCollapsed">{{ item.name }}</span>
                  </NuxtLink>

                  <!-- Submenu -->
                  <ul
                    v-if="item.submenu"
                    :class="[sidebarCollapsed ? '' : 'pl-8']">
                    <li v-for="subItem in item.submenu" :key="subItem.name">
                      <NuxtLink
                        :to="subItem.href"
                        @click="setCurrent(subItem)"
                        :class="[
                          subItem.current
                            ? 'bg-gray-50 dark:bg-gray-800 text-indigo-600 dark:text-mercury-500'
                            : 'text-gray-700 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800',
                          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                        ]">
                        <UIcon
                          :name="subItem.icon"
                          class="w-6 h-6 shrink-0"
                          aria-hidden="true" />
                        <span v-if="!sidebarCollapsed">{{ subItem.name }}</span>
                      </NuxtLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <div
                v-if="!sidebarCollapsed"
                class="text-xs font-semibold leading-6 text-gray-400">
                Shortcuts
              </div>
              <ul
                role="list"
                class="mt-2 space-y-1"
                :class="{ '-ml-4': !sidebarCollapsed }">
                <li v-for="shortcut in shortcuts" :key="shortcut.name">
                  <NuxtLink
                    :to="shortcut.href"
                    @click="setCurrent(shortcut)"
                    :class="[
                      shortcut.current
                        ? 'bg-gray-50 dark:bg-gray-800 text-indigo-600 dark:text-mercury-500'
                        : 'text-gray-700 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800',
                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                    ]">
                    <UIcon
                      :name="shortcut.icon"
                      class="w-6 h-6 shrink-0"
                      aria-hidden="true" />
                    <span v-if="!sidebarCollapsed">{{ shortcut.name }}</span>
                  </NuxtLink>
                </li>
              </ul>
            </li>
            <li
              class="w-full px-0 py-4 mt-auto -mx-2 text-xs border-t vimi-border">
              <UButton
                class="flex items-center w-full gap-1"
                :icon="
                  sidebarCollapsed
                    ? 'i-material-symbols-keyboard-double-arrow-right'
                    : 'i-material-symbols-keyboard-double-arrow-left'
                "
                variant="ghost"
                @click="sidebarCollapsed = !sidebarCollapsed"
                :label="sidebarCollapsed ? '' : 'Collapse Sidebar'">
              </UButton>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div :class="[sidebarCollapsed ? 'xl:pl-[80px]' : 'xl:pl-[240px]']">
      <!-- <VimiBanner /> -->

      <NavBar :sidebar-open="sidebarOpen" @sidebarOpen="sidebarOpen = true" />

      <UMain>
        <!-- Your content -->
        <slot></slot>
      </UMain>

      <VimiFooter class="border-t vimi-border" />
    </div>
  </div>
</template>
